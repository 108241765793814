<template>
  <div class="join done container">
    <div class="baseBox">
      <div class="joinBox">
        <router-link to="/home"> </router-link>
        <h3>회원가입이 <span>완료</span>되었습니다.</h3>
        <h4>회원가입을 환영합니다!</h4>
        <div class="buttonWrap">
          <router-link to="/login">
            <button class="point full">로그인</button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  created() {
    this.$store.dispatch("SET_MENU_VISIBLE", false);
    this.$store.dispatch("SET_NAVBAR_VISIBLE", false);
  },
};
</script>
